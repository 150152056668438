<template>
  <nav class="menu">
    <div class="logo"><router-link to="/">🧑🏻‍💻 Nico Cunningham</router-link></div>
    <ul class="no-bullets">
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/cases">Portfolio</router-link></li>
      <li><a href="https://www.linkedin.com/in/nicolascunn/" target="_blank">LinkedIn</a></li>
      <li><a href="https://twitter.com/NicoCunn" target="_blank">Twitter</a></li>
    </ul>
  </nav>
</template>

<script>
</script>